import * as React from "react"
import {Section} from "../components/Section";
import {Header, Image} from "../components/Header";
import {MenuItem, NavMenu, NavMenuProps} from "../components/NavMenu";
import {Callout} from "../components/Callout";
import {ContentfulRichTextGatsbyReference, RenderRichTextData} from "gatsby-source-contentful/rich-text";
import {Footer, FooterProps} from "../components/Footer";
import {Metadata, MetadataProps} from "../components/Metadata";
import {graphql} from "gatsby";
import { Helmet } from "react-helmet";

export interface ContentfulImage {
  id: string
  description: string
  fixed: {
    src: string
  }
}

export interface ContentfulMenuItem {
  id: string
  title: string
  slug: string
  menuItems: ContentfulMenuItem[]
  page?: {
    slug: string
  }[]
}
export interface ContentfulMenu {
  id: string
  title: string
  menuItems: ContentfulMenuItem[]
}

const PAGE_TYPE_INDEX = "index"

const CONTENT_TYPE_HEADER = "header"
const CONTENT_TYPE_SECTION = "section"
const CONTENT_TYPE_CALLOUT = "callout"
const CONTENT_TYPE_FOOTER = "footer"
const CONTENT_TYPE_METADATA = "metadata"

const getImage = (data: ContentfulImage): Image => {
  return {
    src: data.fixed.src,
    description: data.description
  }
}

const getHref = (item: ContentfulMenuItem): string => {
  if(!item.page) {
    return item.slug
  }

  const pageSlug = item.page[0].slug === PAGE_TYPE_INDEX ? "/" : item.page[0].slug

  return `${pageSlug}#${item.slug}`
}

const getMenuItem = (item: ContentfulMenuItem): MenuItem => {
  return {
    id: item.id,
    title: item.title,
    href: getHref(item),
    items: item.menuItems ? item.menuItems.map(i => ({
      id: i.id,
      title: i.title,
      href: getHref(item),
      items: []
    })) : []
  }
}

const getMenu = (data: ContentfulMenu): NavMenuProps => {
  const items = data.menuItems.map(item => ({
    id: item.id,
    title: item.title,
    href: `${getHref(item)}`,
    items: item?.menuItems?.map(getMenuItem) ?? []
  }))

  return {
    title: data.title,
    items
  }
}

const getMetadataProps = (data: ContentfulMetadata): MetadataProps => {
  return {
    ...data,
    logo: getImage(data.logo),
    employee: {
      ...data.employee,
      image: getImage(data.employee.image)
    }
  }
}

const getBlockByContentType = (contentType: string, block: ContentfulBlock | ContentfulMetadata | FooterProps ): JSX.Element => {
  switch(contentType) {
    case CONTENT_TYPE_CALLOUT:
      return <Callout {...block as ContentfulBlock} />
    case CONTENT_TYPE_HEADER:
      return <Header {...block as ContentfulBlock} logoImage={getImage((block as ContentfulBlock).logo)} mainImage={getImage((block as ContentfulBlock).mainImage)} backgroundImage={getImage((block as ContentfulBlock).backgroundImage)} />
    case CONTENT_TYPE_SECTION:
      return <Section {...block as ContentfulBlock} />
    case CONTENT_TYPE_FOOTER:
      return <Footer {...block as FooterProps} />
    case CONTENT_TYPE_METADATA:
      return <Metadata {...getMetadataProps(block as ContentfulMetadata)} />
    default:
      return <></>
  }
}
const renderBlock = (block: ContentfulBlock): JSX.Element => <div key={block.id} className="container-fluid p-0">{getBlockByContentType(block.sys.contentType.sys.id, block)}</div>

export interface ContentfulBlock {
  id: string
  sys: {
    contentType: {
      sys: {
        id: string
      }
    }
  }
  title: string
  slug: string
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>
  message: string
  type: "Blue Highlight" | "Red Highlight"
  infoLines: string[]
  logo: ContentfulImage
  mainImage: ContentfulImage
  backgroundImage: ContentfulImage
}

export interface ContentfulMetadata {
  name: string
  phone: string
  url: string
  description: string
  email: string
  logo: ContentfulImage
  address: {
    streetAddress1: string
    streetAddress2?: string
    city: string
    state: string
    zipCode: string
  }
  employee: {
    name: string
    jobTitle: string
    image: ContentfulImage
  }
}

export interface PageProps {
    data: {
      contentfulPage: {
        title: string
        slug: string
        menu: ContentfulMenu
        metadata: ContentfulMetadata
        blocks: ContentfulBlock[]
      }
    }
}
const BasePage: React.FC<PageProps> = ({data: {contentfulPage: {title, slug, metadata, menu, blocks}}}): JSX.Element => {
  const href = slug === PAGE_TYPE_INDEX ? "https://lagniappeemdr.com" : `https://lagniappeemdr.com/${slug}`

  return (
    <main className="pt-5">
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={href} />
      </Helmet>
      <NavMenu {...getMenu(menu)} />
      {getBlockByContentType("metadata", metadata)}
      {blocks.map(renderBlock)}
    </main>
  )
}

export const pageQuery = graphql`
  query BasePage($pageId: String) {
    contentfulPage(contentful_id : { eq: $pageId } ) {
      title
      slug
      menu {
        id
        title
        menuItems {
          ... on ContentfulPage {
            id
            title
            slug
          }
          ... on ContentfulSection {
            id
            title
            slug
            page {
              slug
            }
          }
          ... on ContentfulNavSubMenu {
            id
            title
            menuItems {
              ... on ContentfulSection {
                id
                title
                slug
                page {
                  slug
                }
              }
            }
          }
        }
      }
      metadata {
          name
          phone
          url
          description
          email
          logo {
            description
            fixed(width: 500) {
              src
            }
          }
          address {
            streetAddress1
            city
            state
            zipCode
          }
          employee {
            name
            jobTitle
            image {
              description
              fixed(width: 500) {
                src
              }
            }
          }
      }
      blocks {
        ... on ContentfulHeader {
          id
          sys {
          contentType {
            sys {
              id
            }
          }
        }
          infoLines
          mainImage {
            description
            fixed(width: 500) {
              src
            }
          }
          backgroundImage {
            description
            fixed(width: 1920) {
              src
             }
          }
          logo {
            description
            fixed(width: 500) {
              src
            }
          }
        }
        ... on ContentfulSection {
          id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          title
          slug
          body {
            raw
            references {
              ... on ContentfulAsset {
                title
                contentful_id
                __typename
                description
                fixed(width:225) {
                  src
                }
                file {
                  fileName
                  url
                  contentType
                }
              }
              ... on ContentfulMap {
                id
                contentful_id
                __typename
                metadata {
                  address {
                    city
                    streetAddress1
                    zipCode
                    state
                  }
                  email
                  phone
                }
              }
              ... on ContentfulImageCarousel {
                id
                contentful_id
                __typename
                title
                images {
                  id
                  fixed(width:512) {
                    src
                  }
                  file {
                    url
                    contentType
                  }
                  description
                  title
                }
              }
              ... on ContentfulImageGroup {
                id
                contentful_id
                __typename
                images {
                  id
                  fixed(width:512) {
                    src
                  }
                  file {
                    url
                    contentType
                  }
                  description
                  title
                }
              }
            }
          }
        }
        ... on ContentfulCallout {
          id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          message
          type
        }
        ... on ContentfulFooter {
          id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          title
          copyrightLine
        }
      }
    }
  }
`

export default BasePage
