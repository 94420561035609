import {ContentfulRichTextGatsbyReference, RenderRichTextData} from "gatsby-source-contentful/rich-text"
import React from "react"
import {RichText} from "./RichText";

export interface SectionProps {
    title: string
    slug: string
    body: RenderRichTextData<ContentfulRichTextGatsbyReference>
}
export const Section = (props: SectionProps): JSX.Element => {
    const { title, body, slug } = props

    return (
        <div id={slug} className="container d-flex flex-column align-items-center text-start py-4">
            <h4 className="text-center">{title}</h4>
            <RichText body={body} />
        </div>
    )
}