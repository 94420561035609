import {SectionProps} from "./Section";
import React from "react";

export interface FooterProps {
    title: string
    copyrightLine: string
    links: SectionProps[]
}

export const Footer: React.FC<FooterProps> = ({copyrightLine}): JSX.Element => {
    return (
        <div className="container-fluid text-light text-center bg-dark p-3">
            &copy;{copyrightLine}
        </div>
    )
}