import React from "react"

export interface MenuItem {
    id: string
    title: string
    href: string
    items: MenuItem[]
}

export interface NavMenuProps {
    title: string
    items: MenuItem[]
}
export const NavMenu: React.FC<NavMenuProps> = (props): JSX.Element => {
    const renderDropdown = (item: MenuItem): JSX.Element => {
        return (
            <li key={item.id} className="nav-item dropdown mx-1">
                <a id="navbarDropdown" className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {item.title}
                </a>

                <ul key={`${item.id}-dropdown`} className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {item.items.map(i => <li key={i.id}><a className="dropdown-item" href={i.href}>{i.title}</a></li>)}
                </ul>
            </li>
        )
    }

    const renderItem = (item: MenuItem): JSX.Element => {
        if (item.items?.length > 0) {
            return renderDropdown(item)
        }

        return (
            <li key={item.id} className="nav-item mx-1">
                <a className="nav-link" href={item.href}>{item.title}</a>
            </li>
        )
    }

    return (
        <nav key="main-nav" className="navbar navbar-light navbar-expand-lg font-sans-serif font-heading fw-bold bg-light fixed-top shadow-sm border-dark">
            <ul className="navbar-nav container-fluid d-flex flex-row justify-content-md-center justify-content-between">
                <button className="navbar-toggler m-1" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div id="navbarSupportedContent" className="collapse navbar-collapse p-3 p-md-0 flex-grow-0">
                    <a className="navbar-brand h4" href="#">{props.title}</a>
                    {props.items.map(renderItem)}
                </div>
                {/*<li key="simplepractice-book-session-btn" className="nav-item">*/}
                {/*    <button className="btn btn-lg btn-primary" type="button">*/}
                {/*        <a href="https://laura-wood6459.clientsecure.me"*/}
                {/*           className="text-decoration-none text-light"*/}
                {/*           data-spwidget-scope-id="62f1c2db-ff39-4c8c-ad52-ffdf8b1e6d6b"*/}
                {/*           data-spwidget-scope-uri="laura-wood6459"*/}
                {/*           data-spwidget-application-id="7c72cb9f9a9b913654bb89d6c7b4e71a77911b30192051da35384b4d0c6d505b"*/}
                {/*           data-spwidget-scope-global data-spwidget-autobind>Book Session</a>*/}
                {/*    </button>*/}
                {/*</li>*/}
            </ul>
        </nav>
    )
}