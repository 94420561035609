import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import {ContentfulRichTextGatsbyReference, renderRichText, RenderRichTextData} from "gatsby-source-contentful/rich-text"
import React, {ReactNode} from "react"
import {NodeRenderer, Options} from "@contentful/rich-text-react-renderer"
import {ImageCarousel, ImageCarouselProps} from "./ImageCarousel";
import {Map, MapProps} from "./Map";
import {ImageGroup, ImageGroupProps} from "./ImageGroup";

const renderBold: (text: ReactNode) => ReactNode = (text) => <span className="bold">{text}</span>
const renderText: NodeRenderer = (_, children) => <p className="align-center">{children}</p>

const renderList: NodeRenderer = (node, children) => <ul key={node.data.id} className="list-group align-items-start align-items-md-center my-3">{children}</ul>
const renderListItem: NodeRenderer = (node, children) => <li key={node.data.id} className="list-group-item border-0">{children}</li>

const ASSET_TYPE_PDF = "application/pdf"
const CONTENT_TYPE_IMAGE_CAROUSEL = "ContentfulImageCarousel"
const CONTENT_TYPE_MAP = "ContentfulMap"
const CONTENT_TYPE_IMAGE_GROUP = "ContentfulImageGroup"

const renderAsset: NodeRenderer = (node) => {
    const { fixed, description, file, title } = node.data.target

    if(!file) {
        return null
    }

    if(file.contentType === ASSET_TYPE_PDF) {
        return <a href={file.url} title={title} target="_blank">{title}</a>
    }

    if (!fixed) {
        return null
    }
    return <img src={fixed.src} alt={description} className="mx-3 img-thumbnail rounded float-start" />
}

const renderEntry: NodeRenderer = (node) => {
    if(!node.data.target) {
        return <div>no data</div>
    }

    const { __typename } = node.data.target

    if(!__typename) {
        return <div>no entry</div>
    }

    if(__typename === CONTENT_TYPE_IMAGE_CAROUSEL) {
        return <ImageCarousel {...node.data.target as unknown as ImageCarouselProps} />
    }

    if(__typename === CONTENT_TYPE_MAP) {
        return <Map {...node.data.target as unknown as MapProps} />
    }

    if(__typename === CONTENT_TYPE_IMAGE_GROUP) {
        return <ImageGroup {...node.data.target as unknown as ImageGroupProps} />
    }

    return null
}

const options: Options = {
    renderMark: {
        [MARKS.BOLD]: renderBold,
    },
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: renderAsset,
        [BLOCKS.EMBEDDED_ENTRY]: renderEntry,
        [BLOCKS.PARAGRAPH]: renderText,
        [BLOCKS.UL_LIST]: renderList,
        [BLOCKS.LIST_ITEM]: renderListItem,
        [BLOCKS.HEADING_5]: (_, children) => <h5 className="fw-bold text-center">{children}</h5>
    },
}

export interface RichTextProps {
    body: RenderRichTextData<ContentfulRichTextGatsbyReference>
}
export const RichText = (props: RichTextProps): JSX.Element => {
    const { body } = props

    return <>{renderRichText(body, options)}</>
}
