import React from "react"

const TYPE_BLUE_HIGHLIGHT = "Blue Highlight";
const TYPE_RED_HIGHLIGHT = "Red Highlight";

type CalloutType = "Blue Highlight" | "Red Highlight";

export interface CalloutProps {
    message: string
    type: CalloutType
}

const getTypeCssClass = (type: CalloutType) => {
    switch(type) {
        case TYPE_BLUE_HIGHLIGHT:
            return "alert-primary";
        case TYPE_RED_HIGHLIGHT:
            return "alert-danger";
    }
}

export const Callout: React.FC<CalloutProps> = ({message, type}): JSX.Element => {

    return (
        <div className={`container d-flex alert ${getTypeCssClass(type)} justify-content-center align-items-center mt-5`} role="alert">
            {message}
        </div>
    )
}