import React from "react";
import { Helmet } from "react-helmet"
import {Image} from "./Header";

export interface MetadataProps {
    name: string
    phone: string
    url: string
    description: string
    email: string
    logo: Image
    address: {
        streetAddress1: string
        streetAddress2?: string
        city: string
        state: string
        zipCode: string
    }
    employee: {
        name: string
        jobTitle: string
        image: Image
    }
}

export const Metadata: React.FC<MetadataProps> = ({name, phone, url, description, email, logo, address, employee}): JSX.Element => {
    return (
        <Helmet>
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "email": "${email}",
                        "address": {
                            "@type": "PostalAddress",
                            "addressCountry": "United States",
                            "addressLocality": "${address.city}",
                            "addressRegion": "${address.state}",
                            "streetAddress": "${address.streetAddress1}",
                            "postalCode": "${address.zipCode}"
                        },
                        "employee": {
                            "@type": "Person",
                            "name": "${employee.name}",
                            "image": "${employee.image.src}",
                            "jobTitle": "${employee.jobTitle}"
                        },
                        "name": "${name}",
                        "telephone": "${phone}",
                        "logo": "${logo.src}",
                        "url": "${url}",
                        "description": "${description}"
                    }
              `}
            </script>
        </Helmet>
    )
}