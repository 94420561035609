import React from "react"

export interface Image {
    src: string
    description: string
}
export interface HeaderProps {
    mainImage: Image
    backgroundImage: Image
    logoImage: Image
    infoLines: string[]
}
export const Header = (props: HeaderProps): JSX.Element => {
    return (
        <div style={{backgroundImage: `url('${props.backgroundImage.src}')`}} className="d-flex flex-column flex-md-row align-items-center justify-content-center bg-scroll">
            <img src={props.mainImage.src} alt={props.mainImage.description} className="m-4 img-thumbnail rounded" />
            <div className="container-fluid d-flex flex-column text-center">
                <img src={props.logoImage.src} alt={props.logoImage.description} className="mx-auto d-none d-md-block shadow-sm" />
                <h1 dangerouslySetInnerHTML={{__html: props.infoLines.join("<br><br>")}} />
            </div>
        </div>
    )
}