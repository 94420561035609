import React from "react"
import {ContentfulImage} from "../templates/BasePage";

export interface ImageGroupProps {
    images: ContentfulImage[]
}

export const ImageGroup: React.FC<ImageGroupProps> = ({images}): JSX.Element => {

    const renderImage = (image: ContentfulImage) => {
        return <img key={image.id} className="d-block w-100" src={image.fixed.src} alt={image.description} />
    }
    return (
        <div className="d-flex" style={{maxWidth: 512}}>
            {images.map(renderImage)}
        </div>
    )
}