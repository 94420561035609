import React from "react"
import {ContentfulImage} from "../templates/BasePage";
import {Carousel, CarouselItem} from "react-bootstrap";

export interface ImageCarouselProps {
    title: string
    images: ContentfulImage[]
}

export const ImageCarousel: React.FC<ImageCarouselProps> = ({images}): JSX.Element => {

    const renderImage = (image: ContentfulImage) => {
        return (
            <CarouselItem key={image.id}>
                <img className="d-block w-100" src={image.fixed.src} alt={image.description} />
            </CarouselItem>
        )
    }
    return (
        <Carousel style={{maxWidth: 512}}>
            {images.map(renderImage)}
        </Carousel>
    )
}