import React from "react";
import {ContentfulMetadata} from "../templates/BasePage";

export interface MapProps {
    id: string
    metadata: ContentfulMetadata
}

export const Map: React.FC<MapProps> = ({metadata: { email, phone, address: { streetAddress1, streetAddress2, city, state, zipCode}}}) => {
    return (
        <>
            <ul className="list-group my-3">
                <li className="list-group-item d-flex justify-content-start align-items-center border-0">
                    <i className="fas fa-map-marker-alt me-2"></i>
                    {streetAddress1}<br />
                    {streetAddress2 ? <>streetAddress2 <br /></> : null }
                    {city}, {state} {zipCode}
                </li>
                <li className="list-group-item d-flex justify-content-start align-items-center border-0">
                    <i className="fas fa-mobile-alt me-2"></i> <a href={`tel:+${phone}`}>{phone}</a>
                </li>
                <li className="list-group-item d-flex justify-content-start align-items-center border-0">
                    <i className="fas fa-at me-2"></i> {email}
                </li>
            </ul>

            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2687.6815005038766!2d-122.35319688421839!3d47.65175579285048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54901500aacf0b6b%3A0xcd1cb20352af9ac4!2s600%20N%2036th%20St%2C%20Seattle%2C%20WA%2098103!5e0!3m2!1sen!2sus!4v1636925892462!5m2!1sen!2sus"
                className="w-100 mb-5" height="450" frameBorder="0" style={{maxWidth: 600, border: 0}} allowFullScreen={false}
                loading="lazy"></iframe>
        </>
    )
}